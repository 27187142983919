import React, { useContext, useEffect } from "react";
import { CostCenterContext } from "../contexts";
import { STORE_USER } from "../utilities";

const useCostCenters = (rights, datetimefrom, datetimeto) => {
  const {
    state: { costcenters },
    setCostCenter,
  } = useContext(CostCenterContext);
  
  useEffect(() => {
    let user =  localStorage.getItem(STORE_USER)
    let userParse = JSON.parse(user)
    setCostCenter(userParse.id, rights, datetimefrom, datetimeto);
  }, []);

  return costcenters;
};

export default useCostCenters;
